import React from "react"
import Layout from "../components/layout"
import {
  Title,
  Overview,
  Toolset,
  TitleContent,
  Visit,
  WorkedWith,
} from "../components/parts"
import { Car } from "./../components/parts"

const Robin = () => {
  return (
    <Layout title="Robin">
      <Title heading="Robin" sub="Automated reference checks for employers" />
      <Overview
        project="robin"
        text={[
          "Robin automates reference checks for companies. Employers can seek out references using a standardized questionnaire.",
          "I helped them design and build the dashboard for employers and candidates and produce relevant reports.",
        ]}
      />
      <div className="section-bottom">
        <h4>Dashboard</h4>
        <Car
          images={[1, 2, 3, 4, 5, 6].map(i => `/images/robin/robin-${i}.png`)}
          bordered
        />
      </div>
      <TitleContent title="Reference report">
        <iframe
          src="/images/robin/robin-report.pdf"
          className="bordered"
          style={{ width: "100%", height: 840 }}
          title="report"
        />
      </TitleContent>
      <TitleContent title="Feedback report">
        <iframe
          src="/images/robin/feedback-report.pdf"
          className="bordered"
          style={{ width: "100%", height: 420 }}
          title="report"
        />
      </TitleContent>
      <TitleContent title="App workflow">
        <p>
          Robin now has a mobile app in Indonesia for posting and finding jobs
          in the service industry.
        </p>
        <p>
          The mockups are deliberately zoomed-out as the project is not yet
          live.
        </p>
        <img
          src="/images/robin/app-mock-1.png"
          className="bordered"
          alt="app"
        />
        <img
          src="/images/robin/app-mock-2.png"
          className="bordered"
          alt="app"
        />
      </TitleContent>
      <Toolset project="robin" />
      <Visit project="robin" />
      <WorkedWith project="robin" />
    </Layout>
  )
}

export default Robin
